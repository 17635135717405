import React from "react";
import { AppBar, Toolbar, Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

const Header = ({ shopName }) => {
  return (
    <AppBar position="static" sx={{ backgroundColor: "primary.main" }}>
      <Toolbar>
        {/* Left side: Shop Name */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {shopName || "Rental Shop"}
        </Typography>
        {/* Right side: Navigation */}
        {/* <Box>
          <Button color="inherit" component={Link} to="/rental">
            Rental Page
          </Button>
          <Button color="inherit" component={Link} to="/about">
            About
          </Button>
          <Button color="inherit" component={Link} to="/contact">
            Contact
          </Button>
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
