// src/pages/VehicleDetailPage.js
import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Box,
  Button,
  Grid,
  CardMedia,
  TextField,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import VehicleCalendar from "../components/VehicleCalendar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getFunctions, httpsCallable } from "firebase/functions";
import CustomDateRangePicker from "../components/CustomDateRangePicker";

const DEFAULT_OPERATING_HOURS = { open: "08:00", close: "20:00" };

const VehicleDetailPage = () => {
  const { shopSlug, vehicleId } = useParams();
  const navigate = useNavigate();
  const functions = getFunctions();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(true);

  // Image carousel state
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  // Booking form states
  const [showBookingOptions, setShowBookingOptions] = useState(false);
  const [selectedRange, setSelectedRange] = useState([null, null]);
  const [pickupTime, setPickupTime] = useState("");
  const [dropoffTime, setDropoffTime] = useState("");
  const [pickupDateTime, setPickupDateTime] = useState("");
  const [dropoffDateTime, setDropoffDateTime] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [calculatedRate, setCalculatedRate] = useState(null);
  const [rentalRecap, setRentalRecap] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [bookingInitiated, setBookingInitiated] = useState(false);

  const { data: shopData, error: shopError } = useQuery({
    queryKey: ["shop", shopSlug],
    queryFn: async () => {
      const shopQuery = query(
        collection(firestore, "rentalShops"),
        where("slug", "==", shopSlug)
      );
      const shopSnapshot = await getDocs(shopQuery);
      if (!shopSnapshot.empty) {
        return { id: shopSnapshot.docs[0].id, ...shopSnapshot.docs[0].data() };
      } else {
        throw new Error("Shop not found");
      }
    },
  });

  const {
    data: vehicle,
    error: vehicleError,
    isLoading: vehicleLoading,
  } = useQuery({
    queryKey: ["vehicle", vehicleId],
    queryFn: async () => {
      const cachedVehicle = queryClient.getQueryData(["vehicle", vehicleId]);
      if (cachedVehicle) return cachedVehicle;

      if (!shopData?.id) return null;
      const vehicleRef = doc(
        firestore,
        "rentalShops",
        shopData.id,
        "vehicles",
        vehicleId
      );
      const vehicleSnap = await getDoc(vehicleRef);
      if (vehicleSnap.exists()) {
        return { id: vehicleSnap.id, ...vehicleSnap.data() };
      } else {
        throw new Error("Vehicle not found");
      }
    },
    enabled: !!shopData, // ✅ Fetch only when shopData is available
  });

  useEffect(() => {
    setImageLoaded(false);
  }, [currentImageIndex]);

  // When a new date range is selected, update pick-up and drop-off times with no time restriction.
  useEffect(() => {
    if (selectedRange[0] && selectedRange[1]) {
      // Do not set times here; let the user select times.
      // Optionally, you could prefill with a default time.
      // For this example, we leave them empty.
    }
  }, [selectedRange]);

  // When date + time inputs are complete, combine them into full datetime strings.
  useEffect(() => {
    if (selectedRange[0] && selectedRange[1] && pickupTime && dropoffTime) {
      const pickup = moment(selectedRange[0])
        .hour(Number(pickupTime.split(":")[0]))
        .minute(Number(pickupTime.split(":")[1]))
        .second(0);
      const dropoff = moment(selectedRange[1])
        .hour(Number(dropoffTime.split(":")[0]))
        .minute(Number(dropoffTime.split(":")[1]))
        .second(0);
      setPickupDateTime(pickup.toISOString());
      setDropoffDateTime(dropoff.toISOString());
    }
  }, [selectedRange, pickupTime, dropoffTime]);

  // When full datetime strings change, calculate rate and show recap.
  useEffect(() => {
    if (pickupDateTime && dropoffDateTime) {
      calculateRecapAndRate();
    } else {
      setCalculatedRate(null);
      setRentalRecap(null);
      setErrorMessage("");
    }
  }, [pickupDateTime, dropoffDateTime]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const getOperatingHoursFor = (dateMoment) => {
    const dayName = dateMoment.format("dddd");
    if (vehicle.operatingHours && vehicle.operatingHours[dayName]) {
      const slot = Array.isArray(vehicle.operatingHours[dayName])
        ? vehicle.operatingHours[dayName][0]
        : vehicle.operatingHours[dayName];
      return slot;
    }
    return DEFAULT_OPERATING_HOURS;
  };

  // Calculate the recap and full price.
  const calculateRecapAndRate = () => {
    setErrorMessage("");
    const pickup = moment(pickupDateTime);
    const dropoff = moment(dropoffDateTime);

    if (!pickup.isValid() || !dropoff.isValid()) {
      setErrorMessage("One or both of the dates are invalid.");
      return;
    }
    if (dropoff.isSameOrBefore(pickup)) {
      setErrorMessage("Drop-off must be after pick-up.");
      return;
    }

    const pickupHours = getOperatingHoursFor(pickup);
    const dropoffHours = getOperatingHoursFor(dropoff);
    const pickupTimeStr = pickup.format("HH:mm");
    const dropoffTimeStr = dropoff.format("HH:mm");

    if (
      pickupTimeStr < pickupHours.open ||
      pickupTimeStr > pickupHours.close ||
      dropoffTimeStr < dropoffHours.open ||
      dropoffTimeStr > dropoffHours.close
    ) {
      setErrorMessage(
        `Pick-up time must be between ${pickupHours.open} and ${
          pickupHours.close
        } on ${pickup.format("dddd")}, and drop-off time between ${
          dropoffHours.open
        } and ${dropoffHours.close} on ${dropoff.format("dddd")}.`
      );
      return;
    }

    if (vehicle.availability && vehicle.availability.unavailableSlots) {
      const overlaps = vehicle.availability.unavailableSlots.some((slot) => {
        const slotStart = moment(slot.start);
        const slotEnd = moment(slot.end);
        return pickup.isBefore(slotEnd) && dropoff.isAfter(slotStart);
      });
      if (overlaps) {
        setErrorMessage(
          "The vehicle is already rented out during the selected period."
        );
        return;
      }
    }

    const durationHours = dropoff.diff(pickup, "hours", true);
    // Calculate full days and remaining hours.
    const fullDays = durationHours < 24 ? 0 : Math.floor(durationHours / 24);
    const remainingHours = durationHours - fullDays * 24;
    // Determine applicable base rate:
    let selectedRate = Number(vehicle.dailyRate);
    if (
      dropoff.diff(pickup, "hours", true) / 24 >= 30 &&
      vehicle.oneMonthRate
    ) {
      selectedRate = Number(vehicle.oneMonthRate);
    } else if (
      dropoff.diff(pickup, "hours", true) / 24 >= 14 &&
      vehicle.twoWeeksRate
    ) {
      selectedRate = Number(vehicle.twoWeeksRate);
    } else if (
      dropoff.diff(pickup, "hours", true) / 24 >= 7 &&
      vehicle.oneWeekRate
    ) {
      selectedRate = Number(vehicle.oneWeekRate);
    }
    const baseCost = fullDays * selectedRate;
    // For the remaining hours, the first 2 are free; additional hours are charged ₱50 each.
    const freeExtraHours = remainingHours >= 2 ? 2 : remainingHours;
    const paidExtraHours =
      remainingHours > 2 ? Math.ceil(remainingHours - 2) : 0;
    const extraCost = paidExtraHours * 50;
    const totalCost = baseCost + extraCost;

    // Build a recap object with proper pluralization:
    const dayLabel = fullDays === 1 ? "day" : "days";
    const extraHourLabel = paidExtraHours === 1 ? "hour" : "hours";
    const recap = {
      pickup: pickup.format("LLL"),
      dropoff: dropoff.format("LLL"),
      fullDays,
      dayLabel,
      selectedRate,
      baseCost,
      freeExtraHours,
      paidExtraHours,
      extraHourLabel,
      extraCost,
      totalCost,
    };
    setCalculatedRate(totalCost.toFixed(2));
    setRentalRecap(recap);
  };

  const handleConfirmBooking = async () => {
    // Recalculate one more time
    calculateRecapAndRate();
    if (errorMessage || calculatedRate === null || !rentalRecap) {
      alert("Please fix the errors before confirming the booking.");
      return;
    }
    if (!firstName || !lastName || !nationality || !email) {
      alert("Please fill in all your personal details.");
      return;
    }
    const bookingData = {
      shopID: shopData.id,
      vehicleType: `${vehicle.brand} ${vehicle.model}`,
      vehicleId: vehicle.id,
      title: `${firstName} ${lastName}`,
      startDate: moment(pickupDateTime).toISOString(),
      endDate: moment(dropoffDateTime).toISOString(),
      type: "booking",
      dailyPrice: Number(calculatedRate),
      deposit: Number(vehicle.deposit),
      contact: email,
      paid: false,
      paymentMethod: "",
      bookingMethod: "online",
    };

    try {
      setBookingInitiated(true);
      const createBooking = httpsCallable(functions, "createBooking");
      console.log("bookingData: ", bookingData);
      const result = await createBooking(bookingData);
      if (result.data.success) {
        alert("Booking confirmed!");
      }
    } catch (error) {
      console.error("Error confirming booking:", error);
      alert("There was an error confirming your booking. Please try again.");
      setBookingInitiated(false);
    }
  };

  const images =
    vehicle && vehicle.images && vehicle.images.length > 0
      ? vehicle.images
      : vehicle && vehicle.imageUrl
      ? [{ url: vehicle.imageUrl }]
      : [];

  if (vehicleLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, minHeight: "100vh" }}>
        <Typography variant="h6" align="center">
          Loading vehicle details...
        </Typography>
      </Container>
    );
  }

  if (vehicleError) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, minHeight: "100vh" }}>
        <Typography variant="h6" align="center">
          Error: {vehicleError.message}
        </Typography>
      </Container>
    );
  }

  if (!vehicle) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, minHeight: "100vh" }}>
        <Typography variant="h6" align="center">
          Vehicle not found.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper sx={{ p: 3, position: "relative" }}>
        {/* Back Button */}
        <IconButton
          onClick={() => navigate(`/${shopData.slug}`)}
          sx={{ color: "black" }}
        >
          <ArrowBackIosIcon /> Back
        </IconButton>

        {/* Image Carousel */}
        {images.length > 0 && (
          <Box sx={{ position: "relative", mb: 2, height: 400 }}>
            {!imageLoaded && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={400}
                sx={{ position: "absolute", top: 0, left: 0 }}
              />
            )}
            <CardMedia
              component="img"
              image={images[currentImageIndex].url}
              alt={`${vehicle.brand} ${vehicle.model}`}
              onLoad={() => setImageLoaded(true)}
              sx={{
                height: 400,
                objectFit: "cover",
                width: "100%",
                opacity: imageLoaded ? 1 : 0,
                transition: "opacity 0.5s",
              }}
            />
            {images.length > 1 && (
              <>
                <IconButton
                  onClick={handlePrevImage}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    color: "white",
                    "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                  onClick={handleNextImage}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: 0,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    color: "white",
                    "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </>
            )}
          </Box>
        )}

        {/* Vehicle Information */}
        <Typography variant="h4" gutterBottom>
          {vehicle.brand} {vehicle.model}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {vehicle.description}
        </Typography>

        {/* Availability Block */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" gutterBottom>
            Availability
          </Typography>
          <VehicleCalendar availability={vehicle.availability} />
        </Box>

        {/* Pricing Block */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Pricing:
          </Typography>
          <Typography variant="body2">
            Base Daily Rate: ₱{Number(vehicle.dailyRate).toFixed(2)}
          </Typography>
          {vehicle.oneWeekRate && (
            <Typography variant="body2">
              1 Week+ Rate: ₱{Number(vehicle.oneWeekRate).toFixed(2)} per day
            </Typography>
          )}
          {vehicle.twoWeeksRate && (
            <Typography variant="body2">
              2 Weeks+ Rate: ₱{Number(vehicle.twoWeeksRate).toFixed(2)} per day
            </Typography>
          )}
          {vehicle.oneMonthRate && (
            <Typography variant="body2">
              1 Month+ Rate: ₱{Number(vehicle.oneMonthRate).toFixed(2)} per day
            </Typography>
          )}
          <Typography variant="body1">
            <strong>Deposit:</strong> ₱{Number(vehicle.deposit).toFixed(2)}{" "}
            (cash)
          </Typography>
          {vehicle.extras && vehicle.extras.length > 0 && (
            <Typography variant="body1">
              <strong>Extras:</strong>{" "}
              {vehicle.extras.map((e) => e.name).join(", ")}
            </Typography>
          )}
        </Box>

        {/* Book Now Button */}
        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowBookingOptions(true)}
          >
            Book Now
          </Button>
        </Box>

        {/* Booking Options */}
        {showBookingOptions && (
          <>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Select rental dates:
              </Typography>
              <CustomDateRangePicker
                bookedDates={vehicle.availability?.unavailableSlots || []}
                onChange={([start, end]) => {
                  // Check if start date is before today (ignoring time)
                  if (
                    start &&
                    moment(start).isBefore(moment().startOf("day"))
                  ) {
                    setErrorMessage(
                      "Pick-up date must be today or in the future."
                    );
                    setSelectedRange([null, null]);
                  } else {
                    setErrorMessage("");
                    if (start && end) {
                      setSelectedRange([start, end]);
                    }
                  }
                }}
              />
            </Box>

            {/* Time Inputs */}
            {selectedRange[0] && selectedRange[1] && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Select pick-up and drop-off times:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Pick-Up Time"
                      type="time"
                      fullWidth
                      value={pickupTime}
                      onChange={(e) => setPickupTime(e.target.value)}
                      // Allow minutes by not restricting the step
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Drop-Off Time"
                      type="time"
                      fullWidth
                      value={dropoffTime}
                      onChange={(e) => setDropoffTime(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* Recap Section */}
            {pickupTime &&
              dropoffTime &&
              pickupDateTime &&
              dropoffDateTime &&
              !errorMessage &&
              rentalRecap && (
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    border: "1px solid #ccc",
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mb: 1 }}
                  >
                    Booking Recap:
                  </Typography>
                  <Typography variant="body2">
                    <strong>Pick-Up:</strong> {rentalRecap.pickup}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Drop-Off:</strong> {rentalRecap.dropoff}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Full Days:</strong> {rentalRecap.fullDays}{" "}
                    {rentalRecap.fullDays === 1 ? "day" : "days"} at ₱
                    {rentalRecap.selectedRate.toFixed(2)} per day = ₱
                    {rentalRecap.baseCost.toFixed(2)}
                  </Typography>
                  {rentalRecap.paidExtraHours > 0 && (
                    <Typography variant="body2">
                      <strong>Extra Hours:</strong> {rentalRecap.paidExtraHours}{" "}
                      {rentalRecap.paidExtraHours === 1 ? "hour" : "hours"} at
                      ₱50 per hour = ₱{rentalRecap.extraCost.toFixed(2)}
                    </Typography>
                  )}
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    <strong>Total Amount:</strong> ₱
                    {rentalRecap.totalCost.toFixed(2)}
                  </Typography>
                </Box>
              )}

            {/* Customer Details & Confirm Booking */}
            {pickupTime &&
              dropoffTime &&
              pickupDateTime &&
              dropoffDateTime &&
              !errorMessage &&
              rentalRecap && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Enter Your Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        fullWidth
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Last Name"
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Nationality"
                        fullWidth
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email"
                        fullWidth
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  {!bookingInitiated && (
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmBooking}
                      >
                        Confirm Booking
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default VehicleDetailPage;
