// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import RentalShopPage from "./pages/RentalShopPage";
import VehicleDetailPage from "./pages/VehicleDetailPage";
import BookingPage from "./pages/BookingPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"; // ✅ Import React Query

const queryClient = new QueryClient(); // ✅ Initialize Query Client

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {" "}
      {/* ✅ Wrap entire app */}
      <Router>
        <Header shopName="Your Rental Shop Name" />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:slug" element={<RentalShopPage />} />
          <Route path="/:shopSlug/:vehicleId" element={<VehicleDetailPage />} />

          <Route path="/booking" element={<BookingPage />} />
        </Routes>
        <Footer />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
