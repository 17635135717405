// src/components/CustomDateRangePicker.js
import React, { useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import moment from "moment";

const CustomDateRangePicker = ({ bookedDates = [], onChange }) => {
  // bookedDates should be an array of objects with start and end ISO strings.
  // A day is considered booked if it falls within any booked range.
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const startOfMonth = currentMonth.clone().startOf("month");
  const endOfMonth = currentMonth.clone().endOf("month");

  // Generate weeks for the current calendar view.
  const generateCalendar = () => {
    const calendar = [];
    const startCalendar = startOfMonth.clone().startOf("week");
    const endCalendar = endOfMonth.clone().endOf("week");
    let day = startCalendar.clone();
    while (day.isBefore(endCalendar, "day")) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        week.push(day.clone());
        day.add(1, "day");
      }
      calendar.push(week);
    }
    return calendar;
  };

  const calendar = generateCalendar();

  // Determine if a given day is booked.
  const isBooked = (day) => {
    return bookedDates.some(({ start, end }) => {
      const s = moment(start);
      const e = moment(end);
      return day.isSameOrAfter(s, "day") && day.isSameOrBefore(e, "day");
    });
  };

  const handleDayClick = (day) => {
    if (!startDate || (startDate && endDate)) {
      // Start a new selection.
      setStartDate(day);
      setEndDate(null);
      onChange([day.toDate(), null]);
    } else {
      // If clicked day is before start date, reset selection.
      if (day.isBefore(startDate, "day")) {
        setStartDate(day);
        onChange([day.toDate(), null]);
      } else {
        setEndDate(day);
        onChange([startDate.toDate(), day.toDate()]);
      }
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton
          onClick={() =>
            setCurrentMonth(currentMonth.clone().subtract(1, "month"))
          }
        >
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
          {currentMonth.format("MMMM YYYY")}
        </Typography>
        <IconButton
          onClick={() => setCurrentMonth(currentMonth.clone().add(1, "month"))}
        >
          <ArrowForwardIos />
        </IconButton>
      </Box>
      <Grid container spacing={1}>
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((dayName) => (
          <Grid item xs={1.71} key={dayName}>
            <Typography variant="caption" align="center" sx={{ width: "100%" }}>
              {dayName}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {calendar.map((week, idx) => (
        <Grid container spacing={1} key={idx}>
          {week.map((day) => {
            const isSelected =
              (startDate && day.isSame(startDate, "day")) ||
              (endDate && day.isSame(endDate, "day")) ||
              (startDate &&
                endDate &&
                day.isAfter(startDate, "day") &&
                day.isBefore(endDate, "day"));
            const booked = isBooked(day);
            return (
              <Grid item xs={1.71} key={day.format("DD-MM-YYYY")}>
                <Box
                  onClick={() => handleDayClick(day)}
                  sx={{
                    borderRadius: 1,
                    p: 1,
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: isSelected
                      ? "primary.main"
                      : booked
                      ? "error.main"
                      : "grey.200",
                    color: isSelected ? "white" : "black",
                  }}
                >
                  <Typography variant="body2">{day.format("D")}</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      ))}
    </Box>
  );
};

export default CustomDateRangePicker;
