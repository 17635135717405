// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCgPRV4ou6nPYPYrwYQnr4ffG8jQDHBqIg",
  authDomain: "vrental-ph.firebaseapp.com",
  projectId: "vrental-ph",
  storageBucket: "vrental-ph.firebasestorage.app",
  messagingSenderId: "897262602519",
  appId: "1:897262602519:web:5a8f0a170d30a4ce968f52",
  measurementId: "G-6EN0LH0RKQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export services
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
