import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        py: 2,
        mt: 4,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Left side: Copyright */}
          <Typography variant="body2">
            © {new Date().getFullYear()} Rental Shop. All rights reserved.
          </Typography>
          {/* Right side: Navigation */}
          <Box>
            {/* <Button color="inherit" component={Link} to="/rental">
              Rental Page
            </Button>
            <Button color="inherit" component={Link} to="/about">
              About
            </Button>
            <Button color="inherit" component={Link} to="/contact">
              Contact
            </Button> */}
            <Button color="inherit" component={Link} to="/terms">
              Terms & Conditions
            </Button>
            <Button color="inherit" component={Link} to="/privacy">
              Privacy Policy
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
