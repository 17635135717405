import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Box,
  TextField,
  MenuItem,
  Paper,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import VehicleCalendar from "../components/VehicleCalendar";

const RentalShopPage = () => {
  const { slug } = useParams();
  const [filter, setFilter] = useState("");
  const [sortField, setSortField] = useState("brand");
  const queryClient = useQueryClient();

  // ✅ Fetch shop data using React Query
  const {
    data: shopData,
    error: shopError,
    isLoading: shopLoading,
  } = useQuery({
    queryKey: ["shop", slug],
    queryFn: async () => {
      const shopQuery = query(
        collection(firestore, "rentalShops"),
        where("slug", "==", slug)
      );
      const querySnapshot = await getDocs(shopQuery);
      if (!querySnapshot.empty) {
        return {
          id: querySnapshot.docs[0].id,
          ...querySnapshot.docs[0].data(),
        };
      } else {
        throw new Error("Shop not found");
      }
    },
  });

  const {
    data: vehicles,
    error: vehiclesError,
    isLoading: vehiclesLoading,
  } = useQuery({
    queryKey: ["vehicles", shopData?.id],
    queryFn: async () => {
      if (!shopData?.id) return [];

      const vehiclesSnapshot = await getDocs(
        collection(firestore, "rentalShops", shopData.id, "vehicles")
      );

      const vehicleList = vehiclesSnapshot.docs.map((doc) => {
        const vehicleData = { id: doc.id, ...doc.data() };

        // ✅ Store each vehicle in the cache using React Query
        queryClient.setQueryData(["vehicle", vehicleData.id], vehicleData);

        return vehicleData;
      });

      return vehicleList;
    },
    enabled: !!shopData, // ✅ Ensure we fetch only when shopData is available
  });

  // Handle loading states
  if (shopLoading) return <p>Loading shop details...</p>;
  if (shopError) return <p>Error loading shop: {shopError.message}</p>;
  if (!shopData) return <p>Shop not found.</p>;

  // Handle vehicle errors separately
  if (vehiclesError) console.error("Error fetching vehicles:", vehiclesError);

  // Filtering and Sorting Vehicles
  const filteredVehicles = vehicles?.filter((v) =>
    `${v.brand} ${v.model}`.toLowerCase().includes(filter.toLowerCase())
  );

  const sortedVehicles = [...(filteredVehicles || [])].sort((a, b) => {
    if (sortField === "dailyRate") {
      return Number(a.dailyRate) - Number(b.dailyRate);
    }
    return `${a.brand} ${a.model}`.localeCompare(`${b.brand} ${b.model}`);
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Intro Section */}
      {shopData.imageUrl && (
        <Box mb={4}>
          <Box
            component="img"
            src={shopData.imageUrl}
            alt={shopData.name}
            sx={{
              width: "100%",
              maxHeight: 400,
              objectFit: "cover",
              borderRadius: 2,
              mb: 2,
            }}
          />
        </Box>
      )}

      {/* Motorbikes Section */}
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Motorbikes
        </Typography>
        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Search vehicles"
              fullWidth
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Sort By"
              fullWidth
              value={sortField}
              onChange={(e) => setSortField(e.target.value)}
            >
              <MenuItem value="brand">Brand & Model</MenuItem>
              <MenuItem value="dailyRate">Lowest Base Daily Rate</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {vehiclesLoading ? (
            <Typography>Loading vehicles...</Typography>
          ) : sortedVehicles.length > 0 ? (
            sortedVehicles.map((vehicle) => (
              <Grid item xs={12} sm={6} md={4} key={vehicle.id}>
                <Card>
                  {vehicle.images?.[0]?.url && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={vehicle.images[0].url}
                      alt={`${vehicle.brand} ${vehicle.model}`}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6">
                      {vehicle.brand} {vehicle.model}
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Base Daily Rate:</strong> ₱
                        {Number(vehicle.dailyRate).toFixed(2)}
                      </Typography>
                      {vehicle.oneWeekRate && (
                        <Typography variant="body2" color="text.secondary">
                          <strong>1 Week+ Rate:</strong> ₱
                          {Number(vehicle.oneWeekRate).toFixed(2)} per day
                        </Typography>
                      )}
                    </Box>
                    <Box mt={1}>
                      <VehicleCalendar availability={vehicle.availability} />
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      component={Link}
                      to={`/${shopData.slug}/${vehicle.id}`}
                      variant="contained"
                      color="primary"
                    >
                      See Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography>No vehicles available.</Typography>
          )}
        </Grid>
      </Box>

      {/* Contact Section */}
      <Box mt={6} mb={4}>
        <Typography variant="h4" gutterBottom>
          Contact
        </Typography>
        <Paper sx={{ p: 3 }}>
          <Typography variant="body1">
            <strong>Address:</strong> {shopData.address}
          </Typography>
          {shopData.phone && (
            <Typography variant="body1">
              <strong>Phone:</strong> {shopData.phone}
            </Typography>
          )}
          {shopData.email && (
            <Typography variant="body1">
              <strong>Email:</strong> {shopData.email}
            </Typography>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default RentalShopPage;
