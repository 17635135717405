import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";

const PublicHomePage = () => {
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all rental shops from Firestore
  useEffect(() => {
    const fetchShops = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(firestore, "rentalShops")
        );
        const shopsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setShops(shopsData);
      } catch (error) {
        console.error("Error fetching shops:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchShops();
  }, []);

  if (loading) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h6" align="center">
          Loading rental shops...
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Available Rental Shops
      </Typography>
      <Grid container spacing={3}>
        {shops.map((shop) => (
          <Grid item xs={12} sm={6} md={4} key={shop.id}>
            <Card>
              {shop.imageUrl && (
                <CardMedia
                  component="img"
                  height="140"
                  image={shop.imageUrl}
                  alt={shop.name}
                />
              )}
              <CardContent>
                <Typography variant="h6">{shop.name}</Typography>
                {shop.address && (
                  <Typography variant="body2" color="text.secondary">
                    {shop.address}
                  </Typography>
                )}
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  component={Link}
                  to={`/${shop.slug}`}
                  variant="contained"
                  color="primary"
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PublicHomePage;
